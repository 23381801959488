import { etBlack, etWhite } from "@earthtoday/fe-shared-code";
import styled from "styled-components";

import { etGrayDark } from "../../shared/colors";
import transparentize from "../../shared/helpers/transparentize";

export namespace sc {
  export const BottomContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
  `;

  export const TransactionDetail = styled.div`
    width: 100%;
    height: 124px;
    background-color: ${transparentize(etBlack, 0.4)};
    padding: 9px 12px;
  `;
  export const BottomText = styled.p`
    font-family: Roboto Condensed;
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    color: ${etWhite};
    margin: 0;
    padding: 0;
  `;
  export const ReserveName = styled(BottomText)`
    font-weight: bold;
  `;
  export const CollectionName = styled(BottomText)`
    font-weight: bold;
    margin-top: 8px;
  `;

  export const ButtonsContainer = styled.div<{ centered: boolean }>`
    width: 100%;
    height: 44px;
    background-color: ${transparentize(etBlack, 0.4)};
    padding: 0 12px 12px 12px;
    display: flex;
    flex-direction: row;
    justify-content: ${(props) =>
      props.centered ? "center" : "space-between"};
    align-items: flex-end;
  `;
  export const VisitBtn = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 132px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: normal;
    font-family: Roboto Condensed;
    line-height: 20px;
    text-transform: uppercase;
    border: 1px solid ${etWhite};
    background-color: transparent;
    color: ${etWhite};

    &:hover,
    &.hover {
      color: ${etBlack};
      background-color: ${etWhite};
      text-decoration: none;
    }
  `;
  export const GiveBtn = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 132px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: normal;
    font-family: Roboto Condensed;
    line-height: 20px;
    text-transform: uppercase;
    border: none;
    background-color: ${etWhite};
    color: ${etBlack};

    &:hover,
    &.hover {
      color: ${etWhite};
      background-color: transparent;
      border: 1px solid ${etWhite};
      text-decoration: none;
    }
  `;

  export const VisitYourM2 = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 132px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: normal;
    line-height: 32px;
    text-transform: uppercase;
    border: none;
    background-color: ${etWhite};
    color: ${etBlack};

    &:hover {
      color: ${etWhite};
      background-color: transparent;
      border: 1px solid ${etWhite};
      text-decoration: none;
    }
  `;

  export const CollectableContainer = styled.div`
    font-family: Roboto;
    width: 328px;
    height: 496px;
    max-width: 328px;
    max-height: 496px;
    background-color: ${etWhite};
    border-radius: 0.5rem;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    color: ${etGrayDark};
    position: relative;
    overflow: hidden;
  `;

  export const UonInfoLink = styled.a`
    color: inherit;

    :hover {
      text-decoration: none;
      color: inherit;
    }
  `;
}
