import { observer } from "mobx-react-lite";

import {
  CardAbstractCollectable,
  CardAbstractCollectableDriver,
} from "../CardItemPromotion/CardAbstractCollectable";
import {
  CardItemGift,
  CardItemGiftDriver,
} from "../CardItemTransactionStandard/CardItemGift";
import { sc } from "./CardItemTransactionCollectable.styled";
import {
  CardItemTransactionCollectableBottom,
  CardItemTransactionCollectableBottomDriver,
} from "./CardItemTransactionCollectableBottom";

export type CardItemTransactionCollectableDriver =
  CardAbstractCollectableDriver & CardItemTransactionCollectableBottomDriver;

interface IProps {
  driver: CardItemTransactionCollectableDriver;
  cardItemGiftDriver?: CardItemGiftDriver; // dont need this in certificate
}

export const CardItemTransactionCollectable = observer(
  function CardItemTransactionCollectable(props: IProps) {
    return (
      <sc.CollectableContainer>
        {props.cardItemGiftDriver?.shouldRenderGiftItem && (
          <CardItemGift driver={props.cardItemGiftDriver} />
        )}

        <CardAbstractCollectable
          driver={props.driver}
          renderBottom={() => (
            <CardItemTransactionCollectableBottom driver={props.driver} />
          )}
          isUonTransactionCard={true}
          renderSharing={() => <></>}
        />
      </sc.CollectableContainer>
    );
  },
);
